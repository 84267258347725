import { default as commitwhkqME5yckMeta } from "/vercel/path0/pages/commit.vue?macro=true";
import { default as confirm2uAIxw9fKZMeta } from "/vercel/path0/pages/confirm.vue?macro=true";
import { default as easyddMVHjFykCMeta } from "/vercel/path0/pages/easy.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91id_93f6MBSNYZVFMeta } from "/vercel/path0/pages/join/[id].vue?macro=true";
import { default as indexQZTg8uc0JnMeta } from "/vercel/path0/pages/join/index.vue?macro=true";
import { default as sharev8ONwuKEHBMeta } from "/vercel/path0/pages/share.vue?macro=true";
import { default as subscribeBIUrr6xHfNMeta } from "/vercel/path0/pages/subscribe.vue?macro=true";
import { default as _91id_93gI2e59kwhdMeta } from "/vercel/path0/pages/survey/[id].vue?macro=true";
import { default as index5mWB2y0706Meta } from "/vercel/path0/pages/survey/index.vue?macro=true";
import { default as unsubscribeHRFD5K0EoKMeta } from "/vercel/path0/pages/unsubscribe.vue?macro=true";
import { default as verifyYegocVK4w5Meta } from "/vercel/path0/pages/verify.vue?macro=true";
import { default as why9NUx2IQtCBMeta } from "/vercel/path0/pages/why.vue?macro=true";
export default [
  {
    name: commitwhkqME5yckMeta?.name ?? "commit",
    path: commitwhkqME5yckMeta?.path ?? "/commit",
    meta: commitwhkqME5yckMeta || {},
    alias: commitwhkqME5yckMeta?.alias || [],
    redirect: commitwhkqME5yckMeta?.redirect,
    component: () => import("/vercel/path0/pages/commit.vue").then(m => m.default || m)
  },
  {
    name: confirm2uAIxw9fKZMeta?.name ?? "confirm",
    path: confirm2uAIxw9fKZMeta?.path ?? "/confirm",
    meta: confirm2uAIxw9fKZMeta || {},
    alias: confirm2uAIxw9fKZMeta?.alias || [],
    redirect: confirm2uAIxw9fKZMeta?.redirect,
    component: () => import("/vercel/path0/pages/confirm.vue").then(m => m.default || m)
  },
  {
    name: easyddMVHjFykCMeta?.name ?? "easy",
    path: easyddMVHjFykCMeta?.path ?? "/easy",
    meta: easyddMVHjFykCMeta || {},
    alias: easyddMVHjFykCMeta?.alias || [],
    redirect: easyddMVHjFykCMeta?.redirect,
    component: () => import("/vercel/path0/pages/easy.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93f6MBSNYZVFMeta?.name ?? "join-id",
    path: _91id_93f6MBSNYZVFMeta?.path ?? "/join/:id()",
    meta: _91id_93f6MBSNYZVFMeta || {},
    alias: _91id_93f6MBSNYZVFMeta?.alias || [],
    redirect: _91id_93f6MBSNYZVFMeta?.redirect,
    component: () => import("/vercel/path0/pages/join/[id].vue").then(m => m.default || m)
  },
  {
    name: indexQZTg8uc0JnMeta?.name ?? "join",
    path: indexQZTg8uc0JnMeta?.path ?? "/join",
    meta: indexQZTg8uc0JnMeta || {},
    alias: indexQZTg8uc0JnMeta?.alias || [],
    redirect: indexQZTg8uc0JnMeta?.redirect,
    component: () => import("/vercel/path0/pages/join/index.vue").then(m => m.default || m)
  },
  {
    name: sharev8ONwuKEHBMeta?.name ?? "share",
    path: sharev8ONwuKEHBMeta?.path ?? "/share",
    meta: sharev8ONwuKEHBMeta || {},
    alias: sharev8ONwuKEHBMeta?.alias || [],
    redirect: sharev8ONwuKEHBMeta?.redirect,
    component: () => import("/vercel/path0/pages/share.vue").then(m => m.default || m)
  },
  {
    name: subscribeBIUrr6xHfNMeta?.name ?? "subscribe",
    path: subscribeBIUrr6xHfNMeta?.path ?? "/subscribe",
    meta: subscribeBIUrr6xHfNMeta || {},
    alias: subscribeBIUrr6xHfNMeta?.alias || [],
    redirect: subscribeBIUrr6xHfNMeta?.redirect,
    component: () => import("/vercel/path0/pages/subscribe.vue").then(m => m.default || m)
  },
  {
    name: _91id_93gI2e59kwhdMeta?.name ?? "survey-id",
    path: _91id_93gI2e59kwhdMeta?.path ?? "/survey/:id()",
    meta: _91id_93gI2e59kwhdMeta || {},
    alias: _91id_93gI2e59kwhdMeta?.alias || [],
    redirect: _91id_93gI2e59kwhdMeta?.redirect,
    component: () => import("/vercel/path0/pages/survey/[id].vue").then(m => m.default || m)
  },
  {
    name: index5mWB2y0706Meta?.name ?? "survey",
    path: index5mWB2y0706Meta?.path ?? "/survey",
    meta: index5mWB2y0706Meta || {},
    alias: index5mWB2y0706Meta?.alias || [],
    redirect: index5mWB2y0706Meta?.redirect,
    component: () => import("/vercel/path0/pages/survey/index.vue").then(m => m.default || m)
  },
  {
    name: unsubscribeHRFD5K0EoKMeta?.name ?? "unsubscribe",
    path: unsubscribeHRFD5K0EoKMeta?.path ?? "/unsubscribe",
    meta: unsubscribeHRFD5K0EoKMeta || {},
    alias: unsubscribeHRFD5K0EoKMeta?.alias || [],
    redirect: unsubscribeHRFD5K0EoKMeta?.redirect,
    component: () => import("/vercel/path0/pages/unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: verifyYegocVK4w5Meta?.name ?? "verify",
    path: verifyYegocVK4w5Meta?.path ?? "/verify",
    meta: verifyYegocVK4w5Meta || {},
    alias: verifyYegocVK4w5Meta?.alias || [],
    redirect: verifyYegocVK4w5Meta?.redirect,
    component: () => import("/vercel/path0/pages/verify.vue").then(m => m.default || m)
  },
  {
    name: why9NUx2IQtCBMeta?.name ?? "why",
    path: why9NUx2IQtCBMeta?.path ?? "/why",
    meta: why9NUx2IQtCBMeta || {},
    alias: why9NUx2IQtCBMeta?.alias || [],
    redirect: why9NUx2IQtCBMeta?.redirect,
    component: () => import("/vercel/path0/pages/why.vue").then(m => m.default || m)
  }
]