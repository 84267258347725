import revive_payload_client_ZoZD5FJwRG from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@unocss+reset@0.58.8_floating-vue@5.2.2_typescript@5.4.5_unocss@0.58.8_vite@5.2.7_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ymJdyHoWDZ from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@unocss+reset@0.58.8_floating-vue@5.2.2_typescript@5.4.5_unocss@0.58.8_vite@5.2.7_vue-tsc@1.8.27/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_sR3R7TGQ5h from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@unocss+reset@0.58.8_floating-vue@5.2.2_typescript@5.4.5_unocss@0.58.8_vite@5.2.7_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_KWGUoCmCAS from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@unocss+reset@0.58.8_floating-vue@5.2.2_typescript@5.4.5_unocss@0.58.8_vite@5.2.7_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_Ot15tjy2UI from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@unocss+reset@0.58.8_floating-vue@5.2.2_typescript@5.4.5_unocss@0.58.8_vite@5.2.7_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_mJEyx10THd from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@unocss+reset@0.58.8_floating-vue@5.2.2_typescript@5.4.5_unocss@0.58.8_vite@5.2.7_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_BZHD8naUoV from "/vercel/path0/node_modules/.pnpm/@dargmuesli+nuxt-cookie-control@8.2.3_webpack@5.91.0/node_modules/@dargmuesli/nuxt-cookie-control/dist/runtime/plugin.mjs";
import plugin_Y00ChtiT1Q from "/vercel/path0/node_modules/.pnpm/nuxt-bugsnag@7.2.3/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import plugin_QUJKl7CH1E from "/vercel/path0/node_modules/.pnpm/nuxt-directus@5.6.1/node_modules/nuxt-directus/dist/runtime/plugin.mjs";
import slideovers_RQ2ROTInLm from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.15.0_patch_hash=vqj64hhivoranuvszcr2gfewea_nuxt@3.11.1_vite@5.2.7_vue@3.4.21/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_PJ0RMFAAaw from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.15.0_patch_hash=vqj64hhivoranuvszcr2gfewea_nuxt@3.11.1_vite@5.2.7_vue@3.4.21/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_Xtm6DDO1kT from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.15.0_patch_hash=vqj64hhivoranuvszcr2gfewea_nuxt@3.11.1_vite@5.2.7_vue@3.4.21/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_jWG4WARjX8 from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.3.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import plugin_KGfaXjLYE1 from "/vercel/path0/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.11.1_vue@3.4.21/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import chunk_reload_client_B7kBsIXjlf from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@unocss+reset@0.58.8_floating-vue@5.2.2_typescript@5.4.5_unocss@0.58.8_vite@5.2.7_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import error_ldt3PQauZ0 from "/vercel/path0/plugins/error.ts";
import gsap_client_YCLjR5XmPz from "/vercel/path0/plugins/gsap.client.ts";
export default [
  revive_payload_client_ZoZD5FJwRG,
  unhead_ymJdyHoWDZ,
  router_sR3R7TGQ5h,
  payload_client_KWGUoCmCAS,
  check_outdated_build_client_Ot15tjy2UI,
  components_plugin_KR1HBZs4kY,
  prefetch_client_mJEyx10THd,
  plugin_BZHD8naUoV,
  plugin_Y00ChtiT1Q,
  plugin_QUJKl7CH1E,
  slideovers_RQ2ROTInLm,
  modals_PJ0RMFAAaw,
  colors_Xtm6DDO1kT,
  plugin_client_jWG4WARjX8,
  plugin_KGfaXjLYE1,
  chunk_reload_client_B7kBsIXjlf,
  error_ldt3PQauZ0,
  gsap_client_YCLjR5XmPz
]