import type { H3Error, H3Event } from 'h3'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {
    // handle error, e.g. report to a service
    useBugsnag().notify(error as any)
  }

  nuxtApp.hook('vue:error', (error) => {
    // if (process.env.NODE_ENV !== 'production') {
      useBugsnag().notify(error as any)
    // }
  })
  nuxtApp.hook('app:error', (error) => {
    // if (process.env.NODE_ENV !== 'production') {
      useBugsnag().notify(error)
    // }
  })
  nuxtApp.vueApp.config.errorHandler = (error) => {
    // if (process.env.NODE_ENV !== 'production') {
      useBugsnag().notify(error as any)
    // }
  }
})