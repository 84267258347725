<script setup lang="ts">
const env = useRuntimeConfig()
useSeoMeta({
  title: 'Sprawdzone informacje o dostępności i cenie medycznej',
  ogTitle: 'Sprawdzone informacje o dostępności i cenie medycznej',
  description: 'Jesteśmy największą społecznością pacjentów medycznej. Dołącz do aplikacji i podziel się informacjami, bo warto poWiedzieć',
  ogDescription: 'Jesteśmy największą społecznością pacjentów medycznej. Dołącz do aplikacji i podziel się informacjami, bo warto poWiedzieć',
  ogImage: `${env.public.url}/og-image.png`,
})

const gtm = useGtm();
const cookieControl = useCookieControl();

const updateGAConsentData = (status: 'granted' | 'denied') => {
  window.dataLayer = window.dataLayer || [];
  function gtag(a: string, b: string, c: object) { window.dataLayer?.push(arguments); }
  gtag('consent', 'default', {
    ad_storage: status,
    ad_user_data: status,
    ad_personalization: status,
    analytics_storage: status,
    personalization_storage: status,
    functionality_storage: status,
    security_storage: status
  });
}

function cookieConsentGiven() {
  return cookieControl.cookiesEnabledIds.value?.includes('marketing') ? true : false
}
function removeGtmCookiesOnDecline() {
  const gaCookie = useCookie('_ga');
  gaCookie.value = null
}
watch(
  () => cookieControl.cookiesEnabledIds.value,
  (current, previous) => {
    if (
      !previous?.includes('marketing') &&
      current?.includes('marketing')
    ) {
      window.location.reload()
    } else {
      window.location.reload()
    }
  },
  { deep: true },
)
onMounted(() => {
  if (cookieConsentGiven()) {
    updateGAConsentData('granted');
    gtm?.enable(true);
    
  } else {
    updateGAConsentData('denied');
    gtm?.enable(false)
    removeGtmCookiesOnDecline()
  }
});

</script>
<template>
  <Html lang="pl" />

  <Head>
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, maximum-scale=1"
    >
  </Head>
  <CookieControl locale="pl" />
  <NuxtPage />
</template>