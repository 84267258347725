export default defineAppConfig({
  ui: {
    primary: "lime",
    gray: "slate",
    button: {
      block: "shadow-sm w-full text-xl hover:shadow-5 flex justify-center",
      inline: "inline-flex items-center",
    },
    radio: {
      wrapper:
        "shadow-sm bg-white dark:bg-gray-900 text-gray-900 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-gray-700 focus:ring-2 focus:ring-primary-500 dark:focus:ring-primary-400 rounded-md py-2 px-4",
      label: "text-left",
      inner: "w-full",
    },
    radioGroup: {
      fieldset:
        "grid gap-4 text-center justify-items-stretch content-center w-full",
      legend: "text-xl w-full contents",
    },
    checkbox: {
      wrapper:
        "shadow-sm bg-white dark:bg-gray-900 text-gray-900 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-gray-700 focus:ring-2 focus:ring-primary-500 dark:focus:ring-primary-400 rounded-md py-2 px-4",
    },
  },
});
