// Generated by @dargmuesli/nuxt-cookie-control

import type { ModuleOptions } from '#cookie-control/types'

export default {
  "barPosition": "bottom-full",
  "closeModalOnClickOutside": false,
  "colors": {
    "barBackground": "#E3F9DA",
    "barButtonBackground": "#84CC16",
    "barButtonColor": "#fff",
    "barButtonHoverBackground": "#65A30D",
    "barButtonHoverColor": "#fff",
    "barTextColor": "#000",
    "checkboxActiveBackground": "#84CC16",
    "checkboxActiveCircleBackground": "#fff",
    "checkboxDisabledBackground": "gray",
    "checkboxDisabledCircleBackground": "#fff",
    "checkboxInactiveBackground": "gray",
    "checkboxInactiveCircleBackground": "#fff",
    "controlButtonBackground": "#84CC16",
    "controlButtonHoverBackground": "#65A30D",
    "controlButtonIconColor": "#fff",
    "controlButtonIconHoverColor": "#fff",
    "focusRingColor": "#65A30D",
    "modalBackground": "#fff",
    "modalButtonBackground": "#84CC16",
    "modalButtonColor": "#fff",
    "modalButtonHoverBackground": "#65A30D",
    "modalButtonHoverColor": "#fff",
    "modalOverlay": "#000",
    "modalOverlayOpacity": 0.8,
    "modalTextColor": "#000",
    "modalUnsavedColor": "#fff"
  },
  "cookies": {
    "necessary": [
      {
        "description": {
          "pl": "Niezbędne pliki cookie przyczyniają się do użyteczności strony poprzez umożliwianie podstawowych funkcji takich jak nawigacja na stronie i dostęp do bezpiecznych obszarów strony internetowej. Strona internetowa nie może funkcjonować poprawnie bez tych ciasteczek"
        },
        "id": "mandatory",
        "isPreselected": false,
        "name": {
          "pl": "Niezbędne"
        },
        "targetCookieIds": [
          "__cf_bm",
          "ncc_c",
          "ncc_e",
          "cannagram-subscriber",
          "cannagram-referrer"
        ]
      }
    ],
    "optional": [
      {
        "description": {
          "pl": "Statystyczne pliki cookie pomagają właścicielem stron internetowych zrozumieć, w jaki sposób różni użytkownicy zachowują się na stronie, gromadząc i zgłaszając anonimowe informacje."
        },
        "id": "stats",
        "isPreselected": false,
        "name": {
          "pl": "Statystyka"
        },
        "links": {
          "https://privacy.microsoft.com/pl-pl/privacystatement": "Microsoft - Polityka prywatności"
        },
        "targetCookieIds": [
          "c.gif",
          "_clck",
          "_clsk",
          "_cltk",
          "CLID"
        ]
      },
      {
        "description": {
          "pl": "Marketingowe pliki cookie stosowane są w celu śledzenia użytkowników na stronach internetowych. Celem jest wyświetlanie reklam, które są istotne i interesujące dla poszczególnych użytkowników i tym samym bardziej cenne dla wydawców i reklamodawców strony trzeciej."
        },
        "id": "marketing",
        "isPreselected": false,
        "name": {
          "pl": "Marketing"
        },
        "links": {
          "https://business.safety.google/privacy/privacy": "Google - Polityka prywatności",
          "https://privacy.microsoft.com/pl-pl/privacystatement": "Microsoft - Polityka prywatności"
        },
        "targetCookieIds": [
          "_ga",
          "_ga_#",
          "ads/ga-audiences",
          "MR",
          "MUID",
          "SRM_B",
          "ANONCHK",
          "SM"
        ]
      }
    ]
  },
  "cookieExpiryOffsetMs": 31536000000,
  "cookieNameIsConsentGiven": "ncc_c",
  "cookieNameCookiesEnabledIds": "ncc_e",
  "cookieOptions": {
    "path": "/",
    "sameSite": "strict",
    "secure": true
  },
  "isAcceptNecessaryButtonEnabled": true,
  "isControlButtonEnabled": true,
  "isCookieIdVisible": false,
  "isCssEnabled": true,
  "isCssPonyfillEnabled": false,
  "isDashInDescriptionEnabled": true,
  "isIframeBlocked": false,
  "isModalForced": false,
  "locales": [
    "pl",
    "en"
  ],
  "localeTexts": {
    "en": {
      "accept": "Accept",
      "acceptAll": "Accept all",
      "bannerDescription": "We use our own cookies and third-party cookies so that we can display this website correctly and better understand how this website is used, with a view to improving the services we offer. A decision on cookie usage permissions can be changed anytime using the cookie button that will appear after a selection has been made on this banner.",
      "bannerTitle": "Cookies",
      "close": "Close",
      "cookiesFunctional": "Functional cookies",
      "cookiesNecessary": "Necessary cookies",
      "cookiesOptional": "Optional cookies",
      "decline": "Decline",
      "declineAll": "Decline all",
      "here": "here",
      "iframeBlocked": "To see this, please enable functional cookies",
      "manageCookies": "Learn more and customize",
      "save": "Save",
      "settingsUnsaved": "You have unsaved settings"
    },
    "pl": {
      "accept": "Akceptuj",
      "acceptAll": "Akceptuj wszystkie",
      "bannerDescription": "Wykorzystujemy pliki cookie do spersonalizowania treści i reklam, aby oferować funkcje społecznościowe i analizować ruch w naszej witrynie. Informacje o tym, jak korzystasz z naszej witryny, udostępniamy partnerom społecznościowym, reklamowym i analitycznym. Partnerzy mogą połączyć te informacje z innymi danymi otrzymanymi od Ciebie lub uzyskanymi podczas korzystania z ich usług.",
      "bannerTitle": "Niniejsza strona korzysta z plików cookie",
      "close": "Zamknij",
      "cookiesFunctional": "Funkcjonalne pliki cookies",
      "cookiesNecessary": "Niezbędne pliki cookies",
      "cookiesOptional": "Opcjonalne pliki cookies",
      "decline": "Zrezygnuj",
      "declineAll": "Zrezygnuj ze wszystkich",
      "here": "tutaj",
      "iframeBlocked": "W celu wyświetlenia wymagane jest włączenie funkcjonalnych plików cookies",
      "manageCookies": "Dowiedz się więcej",
      "save": "Zapisz",
      "settingsUnsaved": "Masz niezapisane ustawienia"
    }
  }
} as ModuleOptions

/**
* Union of the cookie ids specified in the Nuxt configuration.
*/
export type CookieID = "mandatory" | "stats" | "marketing"

export type CookieIDs = Array<CookieID>
