export default defineNuxtRouteMiddleware(async (to, from) => {
  const user = useUser();
  const { value: token } = useCookie("cannagram-subscriber");

  if (token) {
    const userData = await useRequestFetch()("/api/me", {
      method: "POST",
      body: JSON.stringify({ token }),
    });

    if (userData.status === "OK" && userData.data) {
      user.value = userData.data;
    }
  } else {
    user.value = null;
  }

  if (to.path === "/subscribe") {
    if (user.value) {
      const { intent, current_step } = user.value;

      if (current_step) {
        abortNavigation();
        return navigateTo(`/${current_step}`);
      } else {
        abortNavigation();
        return navigateTo("/survey");
      }
    } else {
      abortNavigation();
      return;
    }
  } else if (to.path === "/commit") {
    if (user.value) {
      const { intent } = user.value;
      if (intent) {
        if (intent === "PATIENT" || intent === "NEW_PATIENT") {
          return navigateTo("/survey");
        } else {
          return navigateTo("/confirm");
        }
      }
    } else {
      abortNavigation();
      return navigateTo(`/subscribe`);
    }
  } else if (to.path === "/confirm") {
    if (user.value) {
      const { status } = user.value;

      if (status === "active") {
        return navigateTo("/share");
      }
    } else {
      abortNavigation();
      return navigateTo("/subscribe");
    }
  } else if(to.path === "/survey") {
    if (user.value) {
      const { intent, current_question_id } = user.value;
    
      if(!intent) {
        abortNavigation();
        return navigateTo("/commit");
      } else if(intent === "PATIENT" || intent === "NEW_PATIENT") {
        if (current_question_id) {
          abortNavigation();
          return  navigateTo(`/survey/${current_question_id}`);
        } else {
          abortNavigation();
          return  navigateTo("/confirm");
        }
      }
    } else {
      abortNavigation();
      return navigateTo(`/subscribe`);
    }
  }

  if (
    !user.value && to?.path !== "/" && to.path !== "/subscribe" &&
    to.path !== "/why" && to.path !== "/easy" && to.path !== "/index2" &&
    !/\/join/.test(to.path) && to.path !== "/verify"
  ) {
    abortNavigation();
    return navigateTo("/");
  }
});
