<script setup lang="ts">
import type { NuxtError } from '#app'

const props = defineProps({
  error: Object as () => NuxtError
})

useSeoMeta({
  title: 'Błąd',
})
</script>

<template>
  <Error :title="`Błąd ${props?.error?.statusCode}`" :message="props?.error?.message">
    <UButton type="link" to="/" size="md">Powrót na stronę główną</UButton>
  </Error>
</template>