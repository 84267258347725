<script setup lang="ts">
const props = defineProps<{
  title?: string | number | null;
  message?: string | null;
}>();

</script>

<template>
  <NuxtLayout name="one">
    <template #header>
      {{ props.title }}
    </template>
    <template #content>

      <div class="text-surface-900 text-justify tracking-tighter mb-4">
        {{ props.message }}
      </div>
      <slot />
    </template>
  </NuxtLayout>
</template>