import { gsap } from 'gsap';
import { MorphSVGPlugin } from 'gsap/MorphSVGPlugin';

export default defineNuxtPlugin((nuxtApp) => {
  if (process.client) {
    gsap.registerPlugin(MorphSVGPlugin);
}

  return {
    provide: {
      gsap,
      MorphSVGPlugin,
    },
  }
})